import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/images/bank_card.png';
const _hoisted_1 = {
  class: "bank-list"
};
const _hoisted_2 = {
  class: "bank-list__box"
};
const _hoisted_3 = {
  class: "bank-list__title"
};
import { router } from '@/router';
import { BankList } from '@/api';
import { ref } from 'vue';
import { formatBankCardNumber } from '@/utils';
export default {
  __name: 'BankList',
  setup(__props) {
    const onClickLeft = () => {
      router.back();
    };
    const bankData = ref(null);
    const _BankList = () => {
      BankList().then(({
        code,
        data
      }) => {
        if (code === 200) {
          bankData.value = data;
        }
      });
    };
    _BankList();
    const jump = () => {
      router.push('/BindBank');
    };
    return (_ctx, _cache) => {
      const _component_van_nav_bar = _resolveComponent("van-nav-bar");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_nav_bar, {
        title: _ctx.$t('Bound card'),
        "left-arrow": "",
        fixed: "",
        placeholder: "",
        onClickLeft: onClickLeft
      }, null, 8, ["title"]), _createElementVNode("div", _hoisted_2, [_createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('Currently bound bank card')), 1), _createElementVNode("div", {
        class: "bank-list__card",
        onClick: _cache[0] || (_cache[0] = $event => jump())
      }, [_cache[1] || (_cache[1] = _createElementVNode("img", {
        src: _imports_0,
        alt: ""
      }, null, -1)), _createElementVNode("p", null, _toDisplayString(bankData.value ? _unref(formatBankCardNumber)(bankData.value.card) : '---- ---- ---- ----'), 1)])])]);
    };
  }
};